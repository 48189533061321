<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ name }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Delete {{ name }}?</h1>
                <!-- <p class="text-caption text-center">Account overview</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p>Tap on the button below to delete this account, including all domains, sites, apps, and other related data.</p>
                    <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                    <v-btn color="red" class="white--text" @click="deleteAccount">Delete Account</v-btn>
                </v-col>
            </v-row>
            <template v-if="isPermitServiceAdmin">
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                    <p>Tap on the button below to delete this account, including all domains, sites, apps, and other related data.</p>
                    <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                    <p class="mb-0 pb-0">
                        <v-btn color="red" class="white--text" @click="deleteAccountImmediately">Delete Account</v-btn>
                    </p>
                </v-col>
            </v-row>
            </template>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        list: [],
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    methods: {
        async loadDomainList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomainList: true });
                const response = this.$client.account(this.$route.params.accountId).currentDomain.list();
                if (Array.isArray(response.list)) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('failed to load account list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
        async deleteAccount() {
            try {
                this.$store.commit('loading', { deleteAccount: true });
                const response = this.$client.account(this.$route.params.accountId).account.delete();
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted account', message: this.$route.params.accountId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteAccount: false });
            }
        },
        /**
         * Service admin can delete the account and data immediately
         */
        async deleteAccountImmediately() {
            try {
                this.$store.commit('loading', { deleteAccountImmediately: true });
                const response = this.$client.main().service.deleteAccount({ accountId: this.$route.params.accountId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted account', message: this.$route.params.accountId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteAccountImmediately: false });
            }
        },
    },
    mounted() {
        this.loadDomainList();
    },
};
</script>
